.flex-r{
    display: flex;
    flex-direction: row;
}
.flex-c{
    display: flex;
    flex-direction: column;
}
.w100p{
    width:100%;
}
.profileCard__Wrapper{
    width: 100%;
    background-color: #fff;
    /* margin: 15px auto; */
    margin-bottom: 15px;
    padding: 15px 15px 0 15px ;
    /* border-radius: 3px; */
}
.profileCard__action{
    margin-bottom:10px;
    margin-top: 10px;
}
.profileCard__action .btn{
    margin-right:10px;
}
.profileCard__action .btn:last-child{
    margin-right:0;
}
/* .aw-eachPost .profileCard__Wrapper{
    margin:0;
} */
.aw-eachPost .profileCard__img-wrap {
    height:60px;
    width:60px;
    border-radius: 5px;
    margin: 0 0 0 10px;
    display: flex;
    background-color: #e2e2e2;
}
.aw-eachPost .profileCard__name{
    font-size: 20px;
    /* line-height: 60px; */
    margin: 0;
}
.profileCard__action .btn{
    border-color: #f2f2f2;
    
}
.pad0{
padding:0;
}
.profileCard__name{
margin: 0;
font-size: 25px;

width: 100%;
overflow: hidden;
text-overflow: ellipsis;
overflow-wrap: break-word;
/* white-space: nowrap; */
}
.profileCard__img-wrap{
    background-color: #e2e2e2;
    margin-right:15px;
    margin-bottom: 10px;
    width:124px;
    height:124px;
    min-width: 124px;
    border-radius: 3px;
    overflow:hidden;
    border-radius: 50%;
}
.profileCard__img{
    width:100%;
    /* height:100%; */
    min-width:100%;
    min-height:100%;
}
.profileCard__main{
overflow: hidden;
}

.profileCard__main-info > *{
margin-bottom:5px;
}
.profileCard__locName{
    /* margin-left: 5px; */
    margin-right: 5px
}
.profileCard__social{
    flex-wrap: wrap;
    /* margin-top:5px; */
    margin-bottom: 10px;
    /* margin-bottom: 5px; */
}

/* .profileCard__action ~ *, .profileCard__Wrapper.miniPrifile .profileCard__main {
    display:none;
} */
/* .profileCard__actionWrap > .profileCard__action {
display:inherit;
} */
.profileCard__social > *{
    /* margin-top: 10px; */
    padding-left: 0;
    padding-right: 5px;
}
.profileCard__action .btn{
    flex:auto;
}
.profileFeedFilter{
    border-top: 1px solid #f2f2f2;
    margin-top: 10px;
}
.profileFeedFilter a{
    color:#333;
    font-weight:600;
    padding:8px;
}
.profileFeedFilter >*:first-child{
padding-left:0;
}


/* .profileBio{
    margin:5px auto;
} */
@media(min-width:768px){
    .profileCard__Wrapper{
        border: solid 1px #e5e5e5;
        border-radius: 3px;
    }
    .profileCard__main-info,.profileCard__actionWrap{
        /* margin-top:60px; */
    }      
}
@media (max-width: 767px) {
    .profileCard__main-info{
        justify-content: center;
    }    
    .profileCard__name{
        font-size: 20px;
    }
    .profileCard__img-wrap{
        width:90px;
        height:90px;
        min-width: 90px;
    }
    .profileCard__actionWrap{
        margin-top:5px;
    }
    .profileCard__Wrapper.miniPrifile .profileCard__actionWrap > *, .profileCard__Wrapper.miniPrifile .profileCard__main {
        display:none;
    }  
    .profileCard__Wrapper.miniPrifile .profileCard__actionWrap .profileCard__action{
        display: inherit;
    } 
}