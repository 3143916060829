/* #region Default styles */
body {
  margin: 0;
  padding: 0 0 100px 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.aw-slide-wrapper {
  /* height:250px; */
  overflow: hidden;
  background-color: #f5f5f5;
  background-color: #fff;
  /* padding: 10px; */
  padding: 15px;
  width: 100%;
  /* border:1px solid #e5e5e5;
    border-radius:2px; */
  display: table;
  content: " ";

  height: 270px;
  position: relative;
  margin-bottom: 15px;
}

.Slide {
  text-align: center;
  position: relative;
  height: 100%;
}

.aw-btn-slide {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 10;
  border: none;
  background: linear-gradient(to right, white 15%, rgba(255, 255, 255, 0) 100%);
}

.aw-btn-slide {
  display: flex;
  place-items: center;
  height: 100%;
  cursor: pointer;
}

.aw-btn-slide>div {
  padding: 10px 15px;
  background-color: white;
  border-radius: 5px;
}

.aw-btn-slide.aw-btn-slide-previous {
  left: 0px;
}

.aw-btn-slide.aw-btn-slide-next {
  right: 0px;
  background: linear-gradient(to left, white 15%, rgba(255, 255, 255, 0) 100%);
}

.App-logo {
  height: 80px;
}

/* DEMO STYLES */
.nav {
  padding: 30px 0;
  background-color: #222;
}

.nav a {
  display: inline-block;
  width: 100px;
  text-align: center;
  margin: 0 15px;
  padding: 10px;
  border: 1px rgba(255, 255, 255, 0.5) solid;
  transition: all 0.3s linear;
  text-decoration: none;
  color: white;
}

.nav a.active {
  background-color: #61DAFB;
  color: #222;
}

section {
  padding: 20px 0;
  max-width: 600px;
  margin: 0 auto;
}

/* #endregion */
/* #region Card */
/* Card */
.card {
  /* border: 3px #f3f3f3 solid; */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s linear;
  cursor: pointer;
  background-color: #f3f3f3;
  padding: 10px;
  max-width: 220px;
  text-align: left;
  margin: 0 auto;
}

.card:hover {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}

.card img {
  max-width: calc(100%);
  margin: 0 auto;
  display: block;
}

.card .index {
  /* background-color: rgba(0, 0, 0, 0.2);
    color: #fff; */
  /* background-color: green;
    color: yellow; */

  background-color: #e2e2e2;
  border-radius: 0 0 5px 5px;
  font-weight: 600;

  line-height: 20px;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 0 10px;
}

.card .price {
  margin: 0;
  padding: 10px 0;
  font-weight: bold;
}

.card .details {
  position: relative;
}

/* .card .features {
    list-style: none;
    padding: 0;
    margin: 0; }
    .card .features li {
      padding-left: 24px;
      margin-right: 10px;
      display: inline-block; }
      .card .features li span {
        display: none; }
      .card .features li.icon-bed {
        background: url(./assets/bed-left-side.svg) left center;
        background-size: auto 100%;
        background-repeat: no-repeat; }
      .card .features li.icon-bath {
        background: url(./assets/bathtub.svg);
        background-size: auto 100%;
        background-repeat: no-repeat; }
      .card .features li.icon-car {
        background: url(./assets/car-compact.svg);
        background-size: auto 100%;
        background-repeat: no-repeat; } */

/* #endregion */
/* #region cards slider */
.cards-slider-wrapper {
  height: 100%;
  /*added */
  display: flex;
  position: absolute;
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.cards-slider-wrapper .card {
  flex: 1;
  /* width:260px; */
  min-width: 200px;
  opacity: 0.7;
  transform: scale(0.8);
  box-shadow: none;
  background-color: white;
  border-color: white;
  transition: opacity 300ms linear, border-color 300ms linear, background-color 300ms linear, transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.cards-slider-wrapper .card .details {
  Xopacity: 0;
  transition: opacity 150ms linear;
}

.col {
  height: 100%;
  position: relative;
}

.cards-slider,
.cards-slider.event-slider {
  position: relative;
  max-width: 226px;
  max-width: 226px;
  height: 100%;
  /* USED TO control layout properties*/
  margin: 0 auto;
}

.cards-slider.event-slider {
  position: relative;
  /* max-width: 226px; */
  /* height:100%; */
  max-width: calc(100vw - 100px);
  height: calc(100% - 70px);
  /* USED TO control layout properties*/
  margin: 0 auto;
}

/* .cards-slider:after {
    content: '';
    display: block;
    width: 100%;
    height: 264px;
    outline: 5px solid #61DAFB;
    position: absolute;
    top: 0;
    left: 0; } */

/* #endregion */

.card {
  width: 900px;
}

.cards-slider .active-slide-card {
  opacity: 1;
  transform: scale(1);
  /* background-color: #f3f3f3; */
  /* border-color: #f3f3f3;  */
}

.cards-slider .active-slide-card .details {
  opacity: 1;
}

.cardSlideView,
.cardW {
  width: 300px;
  max-width: calc(100vw - 50px);
}

.alignFor2 {
  width: 100%;
  /* max-width: calc(100vw - 100px); */
}

@media(min-width:768px) {
  .aw-slide-wrapper {
    /* border:1px solid #e5e5e5; */
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
  }
}