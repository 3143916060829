.loginInner{
    display:flex;
    flex-direction:column;
    flex: 1 1 auto;
}
.loginBody{
    padding:10px;
}
.dialog__body > div{
margin: 7px 0;
}

.dialog__wrap-login input,.dialog__wrap-login a{
    flex: 1 1 auto;
    border-color: transparent;
    width: 50%;
    text-align:center;
}