a {
  color: green;
}

.fa {
  opacity: 0.5;
}

.toolbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  /* background-color: #fff; */
  /* box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); */
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.5);
  height: 56px;
  z-index: 100;
  transition: all 0.5s 0.5s;
}

.main_header_strip {
  /* padding-top: 56px; */
  padding-top: 56px;
  width: 100%;
  position: sticky;
  top: 0;
}

.toolbar.hideHeader {
  transform: translateY(-100px);
}

.theNavWrap {
  max-width: 1200px;
  display: block;
  margin: auto;
  height: 100%;
}

.logo__wrap {
  margin-right: 10px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}
.toolbar__logo a {
  color: #000;
  text-decoration: none;
  font-size: 1.5rem;
}

.toolbar__logo__img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.toolbarRight {
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1;
}

.toolbarMid {
  display: flex;
  /* flex-direction: column; */
  align-items:center;
  flex: 1 1 auto;
  font-size: 16px;
  /* margin-left:10px; */
  line-height: 1;
  /* overflow: hidden; */
  max-height: 100%;
}

.toolbarMid.JAlinkup {
  line-height: inherit;
  font-size:20px;
}

.name__page {
  font-weight: 600;
  text-align: center;
  color: green;
  text-shadow: black;
  font-size: 1em;
  /* letter-spacing: 1px; */
}

/* .name__page.JAlinkup{
font-size:20px;
letter-spacing:2px;
line-height: 1.5;
} */
.filter__page {
  font-size:12px;
  opacity: 0.8;
  text-align: center;
  align-self: center;
  /* text-decoration-line: underline; */
  /* text-underline-position: right; */
  /* text-decoration-style: wavy;  */
}
/* .toolbarMid.JAlinkup .filter__page{
  font-size:12px;
} */
.toolbarMid .fa {
  margin: auto 5px;
  opacity: 0.5;
}

.toolbar__toggle-button {
  margin-left: 15px;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  /* color: white; */
  color: #000;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #fa923f;
}

.call__createPost {
  position: fixed;
  z-index: 100;
  right: 10px;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 25px;
  background-color: green;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transition: all 1s;
}

.toolbar.hideHeader+.call__createPost,
.call__createPost {
  bottom: 10px;
}

.desktop__post {
  margin-top: 5px;
  color: white;
}




.toolbarLeft {
  width: 80px;
  display: flex;
}


.toolbar__toggle-button.desktop__only {
  margin-right: 20px;
}

.toolbarCenter {
  display: block;
  margin: auto;
  max-width: calc(100vw - 160px);
  text-align: center;
}
.aw-Header__switch{
  order:3;
  flex: 1 1 auto;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .toolbar_navigation-items {
    display: none;
  }
  .name__page.hidetrue-sm{
    display:none;
  }
  .toolbar.tb__rounded{
    top: 10px;
    right: 10px;
    left: 10px;
    border-radius: 10px;
  }

  .main_header_strip.tb__rounded {
    padding-top: 76px;
  }

  .main_header_strip a {
    color: #0008;
  }

  .toolbarMid {
    flex-direction: column;
  }

  .desktop__post {
    display: none;
  }

  .desktop__only {
    display: none;
  }

  .call__createPost {
    bottom: 55px;
  }
}

@media (min-width: 768px) {
  .call__createPost {
    width: 200px;
  }

  .mobile__only {
    display: none;
  }
}

@media (min-width: 1000px) {
  .call__createPost {
    /* on right */
    right: auto;
    left: calc(50% + 500px - 100px - 15px);
    transform: translateX(-50%);
    /* on left... */
    /* right: calc(50% + (1000px/2) - 115px);
    transform: translateX(50%); */
  }
}