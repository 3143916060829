.displayOff{display:none;}
.TheContainer{width:650px;}
.fixedBody{position:fixed;}
/* Starts here */
  .aw-eachPost{ 
    overflow:hidden;
    width:100%;
    background-color: #fff;
    position: relative;
    margin-bottom: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: #e5e5e5;
    border-radius:2px;
  }
  .profileCard__Feed{
    padding:15px;
  }
  .aw-abstractWrap,.aw-titleWrap,.aw-reactions,.aw-postAbstract,.aw-postTitle,.aw-postDescription,.aw-postHead{
    margin:15px;
  }
  /* .aw-reactions{
    border-top-style:solid;
    border-top-width:1px 0 0 0;
  } */
  .aw-postHead{padding-right: 30px;}
  .aw-callPostOptions{position: absolute;top: 15px;right: 15px;}
  .aw-reactions .btn{
    font-weight: 600;
    color:#333;
    padding:0;
    /* padding-right:0; */
  }
  .aw-postDescription{
    font-size: 15px;
    color: #333;
    position: relative;
  }
  .aw-postDescription a{
  color:#3339;
  /* font-weight:600; */
}
/* .aw-postEvDate{
  font-weight: 600;
} */

.titleDate{
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 70px;
  text-align: center;
  /* border: 3px solid #e2e2e2; */
  height: 70px;
}
.aw-titleWrap .aw-titleWrap__title,.aw-titleWrap__card a{
  width:100%;
  }
  .aw-titleWrap .titleDate{
    /* font-size: 10px; */
    /* margin-left:-15px; */
    }  
    .aw-titleWrap__card .titleDate{
      /* font-size: 10px; */
      margin-right:15px;
      }     
.aw-card__location{
  font-size:11px;
  opacity:0.7;
}
  .titleDate span:nth-child(1),.titleDate span:nth-child(3){
    font-size:0.7em;
    letter-spacing: 3px;
    color:#333333;
    }
    .titleDate span:nth-child(1){
      letter-spacing: 7px;
      }
    /* .aw-titleWrap .titleDate span:nth-child(2){
      font-size:1.5em;
      color:green;
      } */
      .titleDate span:nth-child(2){
        font-size:1.5em;
        color:green;
        }      
      /* .aw-titleWrap .titleDate span:nth-child(3){
    font-size:0.5em;
    } */
  .aw-mediaWrap{ /*do not add more styles here*/
    /* background-color: #f9f9f9; */
    margin: 15px auto;
    /* border-bottom: 1px solid transparent; */
  }
  .aw-hasMediaWrap{
    border-width:0 0 1px 0;
    border-style: solid;
    border-color: #e5e5e5;
  }
  .mediaImageWrap{background-color: #000;overflow: hidden;}
  .XSXaw-photoImageWrap{
    position: relative;max-width: 100%;
  }
  .aw-linkImageWrap{
    height: 250px;overflow: hidden;
  }
  .aw-videoPlayerWrap{
    position: relative;max-width: 100%;overflow: hidden;
  }
  .aw-videoWrapper {
    position: relative;padding-top: 56.25%;padding-bottom: 25px;height: 0;margin: 0;
  }
  .aw-videoImageWrap{position: absolute;top: 0;left: 0;
    width: 100%;height: 100%;background-color: #333;
  }
  .aw-align,.aw-alignphoto{max-width: 100%;max-height: 590px;display: block;margin-left: auto;margin-right: auto;} 
  .aw-alignlink,.alignV {width: 100%;margin: auto;position: relative;top: 50%;
    -ms-transform: translateY(-50%);-webkit-transform: translateY(-50%);transform: translateY(-50%);
  }
  .playBtn {
    position: absolute;top: 40%;left: 44%;border: 5px solid gold;
    background-color: rgba(0,0,0,0.48);padding: 15px;border-radius: 50%;
  }  
  .arrow-right {
    width: 0;height: 0;border-top: 15px solid transparent;border-bottom: 15px solid transparent;border-left: 30px solid rgba(0,128,0,0.78);
  }   
  .mediaIframe{
    display: none;position: absolute;top: 0;left: 0;width: 100%;height: 100%;
  }   

  .aw-titleWrap,.aw-titleWrap__card {
    max-width: 100%;
    font-size: 18px;
    /* font-weight: 600; */
    display: flex;
    align-items: center;
    height: 100%;
  }
  .aw-titleWrap__card{
    font-size:initial;
    height: 100%;
    font-size:16px;
  }
  .aw-titleWrap__card *{
    max-height:100%;
    color:#333;
  }
  .aw-abstractWrap{color: #777;}
  .aw-ReactComment{margin:auto 20px;}
  .aw-ReactDetails{float:right;}

  @media (max-width: 767px){

    .aw-eachPost,.aw-eachPost .aw-hasMediaWrap{ 
        border-width: 0;
      }    
    .aw-titleWrap {
        font-size: 18px;
    }
  }        
  @media (min-width: 767px){
    .aw-linkImageWrap{
      height:200px;width:200px;float:left;  margin-right: 15px;    
    }
    .Xaw-linkMediaDetails{ width:calc(100% - 200px); float:left;max-height: 200px;
      overflow: hidden;
    }
  } 