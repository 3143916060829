.overlay__container-editor {
    border-radius:7px;
}
.editor__post{
    width:600px; 
    display:flex;
    flex-direction: column;
}
.editor__post>*{
    padding:10px;
}
.aw-mediaWrap.editor__post-postMedia{
    margin:5px 0 0;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}
.editor__post-postMedia-overlay{
    position: absolute;
    /* background-color: red; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.noStyleInput{
    border-color:transparent;
    /* width:100%; */
    outline: none;
    resize: none;
    overflow: hidden;
}
.textAreas{
    border-color:transparent;
    width:100%;
    outline: none;
    resize: none;
    overflow: hidden;    
}
.editor__post-more-input{
    margin-top:10px;
}
.editor__post-message{
    color:green;
    font-weight:600;
}
.editor__post-update.textAreas{
    /* padding: 10px 0 0 0; */
    /* border: 1px solid #e3e3e3;     */
    min-height: 100px;
    line-height: 24px;
}
.editor__post-header{
    border-bottom:1px solid #e5e5e5;
    display:flex;
    flex-direction:row;
    color:green;
}
.editor__post-close{
    margin: 3px 10px 0 0;
}
.editor__post-posttype{
    flex:1 1 auto;
    text-align: right;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #e5e5e5;    
}
.editor__post-posttype select{
    /* border-radius: 7px; */
    border-width: 0;
}
.editor__post-post{
    /* border-radius: 7px; */
    /* border: 1px solid #ccc; */
    color: green;
    /* background-color: #ccc; */
    padding: 0 7px;
    font-weight: 700;
    letter-spacing: 2px;
    cursor: pointer;
}
.editor__post-body{
    overflow-x:hidden;
    overflow-y:auto;
    position: relative;
    padding-top: 5px;
}
.editor__post-username{
    /* position: absolute;
    top: 0;
    left: 10px;
    background-color: #fff;
    padding-right: 5px; */
    font-size: 0.8em;
    align-self: center;
}
.editor__post-media{
    display:flex;
    flex-direction: row;
    /* max-height:100px; */
    flex: 1 1 auto;
    border: 5px solid #ccc;
}
.editor__post-more-input textarea,.editor__post-more-input input{
    width:100%;
}
.editor__post-title-abstract{
    /* min-width:80%; */
    min-width: calc(100% - 110px);
    margin-left: 10px;    
}
.editor__post-photo{
    min-width:100px;
    min-height:100px;
    background-color: #000;
}
.editor__post-photo-img{
    height: 100%;
    display: flex;
}
/* .editor__post .input-photourl{
    opacity:0.5;
} */
.editor__post .photourl-img{
    width: 100%;
    align-self: center;
}
.editor__post-footer{
    border-top:1px solid #e5e5e5;
    display:flex;
    flex-direction:row;
}
.flex_divider{
    flex:1 1 auto;
}
.editor__post label .labelText{
    margin: 0 0 0 5px;
    font-weight: 1;    
}

.editor__post label input{
    width: 30px;  
    margin:0; 
}
.editor__post .fa{
    display:none;
}
@media(max-width:767px){
    .editor__post{
        /* width:100vw; */
        width:calc(100vw - 20px);
        height:calc(100vh - 80px);
        margin-bottom:0;
    }
}