
.toggle-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toggle-button:focus {
    outline: none;
}
.main {
  transition: -webkit-margin-left 0.3s ease-out;
  transition: margin-left 0.3s ease-out;
  transition: margin-left 0.3s ease-out, -webkit-transform 0.3s ease-out;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.show__in__mobile {
  float: right;
}

.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  max-width: calc(100vw - 50px);
  z-index: 200;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

/* .side-drawer.right {
  transform: translateX(100%);
  right:0;
  left:auto;
} */
.side-drawer.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.side-drawer.right.open {
  /* transform: translateX(100%); */
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* justify-content: center; */
  padding-top:60px;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a {
  color: #521751;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #fa923f;
}

.KEEPmainContentWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

@media (min-width: 768px) {
  .show__in__mobile {
    display: none;
    margin-left: calc(((100vw - 1000px)/2) - 300px);
  }

  .side-drawer {
    padding-top: 56px;
  }

  .side-drawer.right {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    right: 0;
    left: auto;
  }

  .side-drawer.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

}

@media (max-width: 767px) {
  .main {
    margin-top: 0;
  }

  .toolbar__logo {
    margin-left: 0;
  }

  .side-drawer {
    z-index: 202;
  }

  /* .main_header_strip{
    padding-top:76px;
  }
  .main_header_strip a{
    color:#000;
  } */
}
.loginInner{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}
.loginBody{
    padding:10px;
}
.dialog__body > div{
margin: 7px 0;
}

.dialog__wrap-login input,.dialog__wrap-login a{
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    border-color: transparent;
    width: 50%;
    text-align:center;
}
.overlay__bg {
    background: #000;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: .7;
    z-index: 300;
}

.overlay__container {
    position: fixed;
    /* max-width:100%;
    max-height:100%; */
    max-width:100vw;
    max-height:100vh;

    overflow:hidden;
    z-index: 301;

    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;  
    background-color: #fff; 
    padding:10px; 
}
.overlay__container>*{
    position:relative;
    min-width:250px;
    max-width:100vw;
    max-height:100vh;
}

.overlay__container-login input,.overlay__container-login  a{
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    border-color: transparent;
    width: 50%;
    text-align:center;
}

.dialog__list-items{
    padding: 5px 0;
}
.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 200;
}
.aw-MainNav{
  display:-webkit-flex;
  display:flex;
  -webkit-align-items: center;
          align-items: center;
}
.aw-MainNav .aw-MainNav-item{
  margin:auto 15px;
  text-align: center;
  font-size: 12px;
}
.aw-MainNav-login{
  font-weight: 800;
  letter-spacing: 1px;
  color: green;
  cursor: pointer;
}
.aw-MainNav a{
  color:#000;
}
@media (max-width: 767px) {
  .aw-MainNav{
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
    display:-webkit-flex;
    display:flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    position:fixed;
    bottom:0;
    left:0;
    right:0; 
    height:50px;
    transition: all 0.2s ease-in-out;
    transition-delay: 1s;
  }
  .aw-MainNav-switch{
    width:40%;
  }
  .aw-MainNav  .switchDesigner {
    height: 40px;
    border-radius: 5px;
  }

  .aw-MainNav .aw-MainNav-item{
    -webkit-flex:1 1;
            flex:1 1;
    margin:auto 5px;
  }
  .hideHeader .aw-MainNav{
    opacity:0;  
    transition: all 0s;
  }  
}
@media (min-width: 768px) {
  .call__createPost{
    width:200px;
  }
  .aw-MainNav i{
    display:none;
  }  
  .aw-MainNav .aw-MainNav-item{
    font-size: 14px;
    letter-spacing: 1px;
  }    
}
  
.overlay__container-editor {
    border-radius:7px;
}
.editor__post{
    width:600px; 
    display:-webkit-flex; 
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.editor__post>*{
    padding:10px;
}
.aw-mediaWrap.editor__post-postMedia{
    margin:5px 0 0;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}
.editor__post-postMedia-overlay{
    position: absolute;
    /* background-color: red; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.noStyleInput{
    border-color:transparent;
    /* width:100%; */
    outline: none;
    resize: none;
    overflow: hidden;
}
.textAreas{
    border-color:transparent;
    width:100%;
    outline: none;
    resize: none;
    overflow: hidden;    
}
.editor__post-more-input{
    margin-top:10px;
}
.editor__post-message{
    color:green;
    font-weight:600;
}
.editor__post-update.textAreas{
    /* padding: 10px 0 0 0; */
    /* border: 1px solid #e3e3e3;     */
    min-height: 100px;
    line-height: 24px;
}
.editor__post-header{
    border-bottom:1px solid #e5e5e5;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:row;
            flex-direction:row;
    color:green;
}
.editor__post-close{
    margin: 3px 10px 0 0;
}
.editor__post-posttype{
    -webkit-flex:1 1 auto;
            flex:1 1 auto;
    text-align: right;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #e5e5e5;    
}
.editor__post-posttype select{
    /* border-radius: 7px; */
    border-width: 0;
}
.editor__post-post{
    /* border-radius: 7px; */
    /* border: 1px solid #ccc; */
    color: green;
    /* background-color: #ccc; */
    padding: 0 7px;
    font-weight: 700;
    letter-spacing: 2px;
    cursor: pointer;
}
.editor__post-body{
    overflow-x:hidden;
    overflow-y:auto;
    position: relative;
    padding-top: 5px;
}
.editor__post-username{
    /* position: absolute;
    top: 0;
    left: 10px;
    background-color: #fff;
    padding-right: 5px; */
    font-size: 0.8em;
    -webkit-align-self: center;
            align-self: center;
}
.editor__post-media{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    /* max-height:100px; */
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    border: 5px solid #ccc;
}
.editor__post-more-input textarea,.editor__post-more-input input{
    width:100%;
}
.editor__post-title-abstract{
    /* min-width:80%; */
    min-width: calc(100% - 110px);
    margin-left: 10px;    
}
.editor__post-photo{
    min-width:100px;
    min-height:100px;
    background-color: #000;
}
.editor__post-photo-img{
    height: 100%;
    display: -webkit-flex;
    display: flex;
}
/* .editor__post .input-photourl{
    opacity:0.5;
} */
.editor__post .photourl-img{
    width: 100%;
    -webkit-align-self: center;
            align-self: center;
}
.editor__post-footer{
    border-top:1px solid #e5e5e5;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:row;
            flex-direction:row;
}
.flex_divider{
    -webkit-flex:1 1 auto;
            flex:1 1 auto;
}
.editor__post label .labelText{
    margin: 0 0 0 5px;
    font-weight: 1;    
}

.editor__post label input{
    width: 30px;  
    margin:0; 
}
.editor__post .fa{
    display:none;
}
@media(max-width:767px){
    .editor__post{
        /* width:100vw; */
        width:calc(100vw - 20px);
        height:calc(100vh - 80px);
        margin-bottom:0;
    }
}
.displayOff{display:none;}
.TheContainer{width:650px;}
.fixedBody{position:fixed;}
/* Starts here */
  .aw-eachPost{ 
    overflow:hidden;
    width:100%;
    background-color: #fff;
    position: relative;
    margin-bottom: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: #e5e5e5;
    border-radius:2px;
  }
  .profileCard__Feed{
    padding:15px;
  }
  .aw-abstractWrap,.aw-titleWrap,.aw-reactions,.aw-postAbstract,.aw-postTitle,.aw-postDescription,.aw-postHead{
    margin:15px;
  }
  /* .aw-reactions{
    border-top-style:solid;
    border-top-width:1px 0 0 0;
  } */
  .aw-postHead{padding-right: 30px;}
  .aw-callPostOptions{position: absolute;top: 15px;right: 15px;}
  .aw-reactions .btn{
    font-weight: 600;
    color:#333;
    padding:0;
    /* padding-right:0; */
  }
  .aw-postDescription{
    font-size: 15px;
    color: #333;
    position: relative;
  }
  .aw-postDescription a{
  color:#3339;
  /* font-weight:600; */
}
/* .aw-postEvDate{
  font-weight: 600;
} */

.titleDate{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 70px;
  text-align: center;
  /* border: 3px solid #e2e2e2; */
  height: 70px;
}
.aw-titleWrap .aw-titleWrap__title,.aw-titleWrap__card a{
  width:100%;
  }
  .aw-titleWrap .titleDate{
    /* font-size: 10px; */
    /* margin-left:-15px; */
    }  
    .aw-titleWrap__card .titleDate{
      /* font-size: 10px; */
      margin-right:15px;
      }     
.aw-card__location{
  font-size:11px;
  opacity:0.7;
}
  .titleDate span:nth-child(1),.titleDate span:nth-child(3){
    font-size:0.7em;
    letter-spacing: 3px;
    color:#333333;
    }
    .titleDate span:nth-child(1){
      letter-spacing: 7px;
      }
    /* .aw-titleWrap .titleDate span:nth-child(2){
      font-size:1.5em;
      color:green;
      } */
      .titleDate span:nth-child(2){
        font-size:1.5em;
        color:green;
        }      
      /* .aw-titleWrap .titleDate span:nth-child(3){
    font-size:0.5em;
    } */
  .aw-mediaWrap{ /*do not add more styles here*/
    /* background-color: #f9f9f9; */
    margin: 15px auto;
    /* border-bottom: 1px solid transparent; */
  }
  .aw-hasMediaWrap{
    border-width:0 0 1px 0;
    border-style: solid;
    border-color: #e5e5e5;
  }
  .mediaImageWrap{background-color: #000;overflow: hidden;}
  .XSXaw-photoImageWrap{
    position: relative;max-width: 100%;
  }
  .aw-linkImageWrap{
    height: 250px;overflow: hidden;
  }
  .aw-videoPlayerWrap{
    position: relative;max-width: 100%;overflow: hidden;
  }
  .aw-videoWrapper {
    position: relative;padding-top: 56.25%;padding-bottom: 25px;height: 0;margin: 0;
  }
  .aw-videoImageWrap{position: absolute;top: 0;left: 0;
    width: 100%;height: 100%;background-color: #333;
  }
  .aw-align,.aw-alignphoto{max-width: 100%;max-height: 590px;display: block;margin-left: auto;margin-right: auto;} 
  .aw-alignlink,.alignV {width: 100%;margin: auto;position: relative;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);
  }
  .playBtn {
    position: absolute;top: 40%;left: 44%;border: 5px solid gold;
    background-color: rgba(0,0,0,0.48);padding: 15px;border-radius: 50%;
  }  
  .arrow-right {
    width: 0;height: 0;border-top: 15px solid transparent;border-bottom: 15px solid transparent;border-left: 30px solid rgba(0,128,0,0.78);
  }   
  .mediaIframe{
    display: none;position: absolute;top: 0;left: 0;width: 100%;height: 100%;
  }   

  .aw-titleWrap,.aw-titleWrap__card {
    max-width: 100%;
    font-size: 18px;
    /* font-weight: 600; */
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
  }
  .aw-titleWrap__card{
    font-size:initial;
    height: 100%;
    font-size:16px;
  }
  .aw-titleWrap__card *{
    max-height:100%;
    color:#333;
  }
  .aw-abstractWrap{color: #777;}
  .aw-ReactComment{margin:auto 20px;}
  .aw-ReactDetails{float:right;}

  @media (max-width: 767px){

    .aw-eachPost,.aw-eachPost .aw-hasMediaWrap{ 
        border-width: 0;
      }    
    .aw-titleWrap {
        font-size: 18px;
    }
  }        
  @media (min-width: 767px){
    .aw-linkImageWrap{
      height:200px;width:200px;float:left;  margin-right: 15px;    
    }
    .Xaw-linkMediaDetails{ width:calc(100% - 200px); float:left;max-height: 200px;
      overflow: hidden;
    }
  } 
a {
  color: green;
}

.fa {
  opacity: 0.5;
}

.toolbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  /* background-color: #fff; */
  /* box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); */
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.5);
  height: 56px;
  z-index: 100;
  transition: all 0.5s 0.5s;
}

.main_header_strip {
  /* padding-top: 56px; */
  padding-top: 56px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.toolbar.hideHeader {
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
}

.theNavWrap {
  max-width: 1200px;
  display: block;
  margin: auto;
  height: 100%;
}

.logo__wrap {
  margin-right: 10px;
}

.toolbar__navigation {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 1rem;
}
.toolbar__logo a {
  color: #000;
  text-decoration: none;
  font-size: 1.5rem;
}

.toolbar__logo__img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.toolbarRight {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.spacer {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.toolbarMid {
  display: -webkit-flex;
  display: flex;
  /* flex-direction: column; */
  -webkit-align-items:center;
          align-items:center;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  font-size: 16px;
  /* margin-left:10px; */
  line-height: 1;
  /* overflow: hidden; */
  max-height: 100%;
}

.toolbarMid.JAlinkup {
  line-height: inherit;
  font-size:20px;
}

.name__page {
  font-weight: 600;
  text-align: center;
  color: green;
  text-shadow: black;
  font-size: 1em;
  /* letter-spacing: 1px; */
}

/* .name__page.JAlinkup{
font-size:20px;
letter-spacing:2px;
line-height: 1.5;
} */
.filter__page {
  font-size:12px;
  opacity: 0.8;
  text-align: center;
  -webkit-align-self: center;
          align-self: center;
  /* text-decoration-line: underline; */
  /* text-underline-position: right; */
  /* text-decoration-style: wavy;  */
}
/* .toolbarMid.JAlinkup .filter__page{
  font-size:12px;
} */
.toolbarMid .fa {
  margin: auto 5px;
  opacity: 0.5;
}

.toolbar__toggle-button {
  margin-left: 15px;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  /* color: white; */
  color: #000;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #fa923f;
}

.call__createPost {
  position: fixed;
  z-index: 100;
  right: 10px;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 25px;
  background-color: green;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transition: all 1s;
}

.toolbar.hideHeader+.call__createPost,
.call__createPost {
  bottom: 10px;
}

.desktop__post {
  margin-top: 5px;
  color: white;
}




.toolbarLeft {
  width: 80px;
  display: -webkit-flex;
  display: flex;
}


.toolbar__toggle-button.desktop__only {
  margin-right: 20px;
}

.toolbarCenter {
  display: block;
  margin: auto;
  max-width: calc(100vw - 160px);
  text-align: center;
}
.aw-Header__switch{
  -webkit-order:3;
          order:3;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .toolbar_navigation-items {
    display: none;
  }
  .name__page.hidetrue-sm{
    display:none;
  }
  .toolbar.tb__rounded{
    top: 10px;
    right: 10px;
    left: 10px;
    border-radius: 10px;
  }

  .main_header_strip.tb__rounded {
    padding-top: 76px;
  }

  .main_header_strip a {
    color: #0008;
  }

  .toolbarMid {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .desktop__post {
    display: none;
  }

  .desktop__only {
    display: none;
  }

  .call__createPost {
    bottom: 55px;
  }
}

@media (min-width: 768px) {
  .call__createPost {
    width: 200px;
  }

  .mobile__only {
    display: none;
  }
}

@media (min-width: 1000px) {
  .call__createPost {
    /* on right */
    right: auto;
    left: calc(50% + 500px - 100px - 15px);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    /* on left... */
    /* right: calc(50% + (1000px/2) - 115px);
    transform: translateX(50%); */
  }
}
.switchWrapper{
  display:-webkit-flex;
  display:flex;
  position:relative;
}
.switchWrapper label{
  margin-bottom:0;
  width:100%;
}
.switchDesigner{
  background-color: #e2e2e2;

  /*switch size*/
  /* width: 120px; */
  width: 100%;
  height: 25px; 
  min-width: 120px;

  /*switch labels*/
  font-weight: 300; font-size:11px; color: #000; 
  
  /* remove for square edges */
  border-radius: 999px; 
}
/* input[type="checkbox"].switch:checked + .switchDesigner {
  background-color: #008000;
} */

  input[type="checkbox"].switch {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin:auto;
  }
  input[type="checkbox"].switch + div {
    position: relative;
    vertical-align: middle;
    transition-duration: 0.4s;
    transition-property: background-color, box-shadow;
    cursor: pointer;
  }
  input[type="checkbox"].switch + div > div {
    position: absolute;
    width: calc(50% - 1px);
    height: calc(100% - 2px);
    border-radius: inherit;
    background: #fff;
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-duration: 0.4s;
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform;
    pointer-events: none;
    margin-top: 1px;
    margin-left: 1px;
  }
  input[type="checkbox"].switch + div span {
    position: absolute;
    width:50%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding:2px;
    text-align: center;
    font-size: 1.0em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }

  input[type="checkbox"].switch + div span.switchLabel2 {
    right:0;
  }

  input[type="checkbox"].switch:checked + div > div {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
h2 {
    color: white;
  }
  .strip__scroller{
    margin: auto;
    /* max-width: 1000px; */
    max-width: 100%;
  }
  .scrollControls{
      display:none;
  }
  .scrolling-wrap{
    position:relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    scroll-snap-align: center;
    /* max-width: 1000px; */
  }

  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .scrolling-wrapper .scrolling-card {
    display: inline-block;
  }
  
  .scrolling-wrapper-flexbox {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    overflow-x: auto;
  }
  .scrolling-wrapper-flexbox .scrolling-card {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    /* margin-bottom: 10px;     */
  }
  
  .scrolling-card {
    /* border: 2px solid red; */
    /* width: 90px; */
    max-width:100px;

    color:#333;
    padding:10px;
    /* height:150px; */
    /* height: 75px; */
    /* height: 100%; */
    margin:auto;
    /* background: black; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* justify-items: center; */
    -webkit-align-items: center;
            align-items: center;
  }
  .scrolling-img-wrap{
      width:50px;
      height:50px;
      overflow:hidden;
      border-radius:50px;
      background-color: #e2e2e2;
      display: -webkit-flex;
      display: flex;
    place-content: center;
  }
  .scrolling-img-wrap>.fa{
    font-size: 45px;
    font-size: 30px;
    padding: 10px;
    color: #f7f7f7;
  }
  .scrolling-img-wrap > img{
    min-width: 100%;
    max-height:100%
  }
  .scrolling-name{
      width:100%;
      text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
  
  .scrolling-wrapper, .scrolling-wrapper-flexbox {
    max-width:100%;
    -webkit-overflow-scrolling: touch;
  }
  @media(max-width:767px){
      .scrolling-wrapper::-webkit-scrollbar, .scrolling-wrapper-flexbox::-webkit-scrollbar {
        display: none;
      }
  }

  .prev, .next {
    /* padding-top: 10px;
    padding-bottom: 10px; */
    padding:10px;
    height: 100%;
    cursor: pointer;
    color: black;
    transition: 0.6s ease;
  }
  
  .next {
    margin-left: 5px;
  }
  
  .prev {
    margin-right: 5px;
  }
  
  .prev:hover, .next:hover {
    color: white;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
  }
  .loadMore {
    background-color: yellow;
    border:10px solid green;
  }
  .loadMore.Cls0{
    background-color:green;
    border:10px solid green;
  }

.flex-r{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.flex-c{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.w100p{
    width:100%;
}
.profileCard__Wrapper{
    width: 100%;
    background-color: #fff;
    /* margin: 15px auto; */
    margin-bottom: 15px;
    padding: 15px 15px 0 15px ;
    /* border-radius: 3px; */
}
.profileCard__action{
    margin-bottom:10px;
    margin-top: 10px;
}
.profileCard__action .btn{
    margin-right:10px;
}
.profileCard__action .btn:last-child{
    margin-right:0;
}
/* .aw-eachPost .profileCard__Wrapper{
    margin:0;
} */
.aw-eachPost .profileCard__img-wrap {
    height:60px;
    width:60px;
    border-radius: 5px;
    margin: 0 0 0 10px;
    display: -webkit-flex;
    display: flex;
    background-color: #e2e2e2;
}
.aw-eachPost .profileCard__name{
    font-size: 20px;
    /* line-height: 60px; */
    margin: 0;
}
.profileCard__action .btn{
    border-color: #f2f2f2;
    
}
.pad0{
padding:0;
}
.profileCard__name{
margin: 0;
font-size: 25px;

width: 100%;
overflow: hidden;
text-overflow: ellipsis;
overflow-wrap: break-word;
/* white-space: nowrap; */
}
.profileCard__img-wrap{
    background-color: #e2e2e2;
    margin-right:15px;
    margin-bottom: 10px;
    width:124px;
    height:124px;
    min-width: 124px;
    border-radius: 3px;
    overflow:hidden;
    border-radius: 50%;
}
.profileCard__img{
    width:100%;
    /* height:100%; */
    min-width:100%;
    min-height:100%;
}
.profileCard__main{
overflow: hidden;
}

.profileCard__main-info > *{
margin-bottom:5px;
}
.profileCard__locName{
    /* margin-left: 5px; */
    margin-right: 5px
}
.profileCard__social{
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    /* margin-top:5px; */
    margin-bottom: 10px;
    /* margin-bottom: 5px; */
}

/* .profileCard__action ~ *, .profileCard__Wrapper.miniPrifile .profileCard__main {
    display:none;
} */
/* .profileCard__actionWrap > .profileCard__action {
display:inherit;
} */
.profileCard__social > *{
    /* margin-top: 10px; */
    padding-left: 0;
    padding-right: 5px;
}
.profileCard__action .btn{
    -webkit-flex:auto;
            flex:auto;
}
.profileFeedFilter{
    border-top: 1px solid #f2f2f2;
    margin-top: 10px;
}
.profileFeedFilter a{
    color:#333;
    font-weight:600;
    padding:8px;
}
.profileFeedFilter >*:first-child{
padding-left:0;
}


/* .profileBio{
    margin:5px auto;
} */
@media(min-width:768px){
    .profileCard__Wrapper{
        border: solid 1px #e5e5e5;
        border-radius: 3px;
    }
    .profileCard__main-info,.profileCard__actionWrap{
        /* margin-top:60px; */
    }      
}
@media (max-width: 767px) {
    .profileCard__main-info{
        -webkit-justify-content: center;
                justify-content: center;
    }    
    .profileCard__name{
        font-size: 20px;
    }
    .profileCard__img-wrap{
        width:90px;
        height:90px;
        min-width: 90px;
    }
    .profileCard__actionWrap{
        margin-top:5px;
    }
    .profileCard__Wrapper.miniPrifile .profileCard__actionWrap > *, .profileCard__Wrapper.miniPrifile .profileCard__main {
        display:none;
    }  
    .profileCard__Wrapper.miniPrifile .profileCard__actionWrap .profileCard__action{
        display: inherit;
    } 
}
/* #region Default styles */
body {
  margin: 0;
  padding: 0 0 100px 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.aw-slide-wrapper {
  /* height:250px; */
  overflow: hidden;
  background-color: #f5f5f5;
  background-color: #fff;
  /* padding: 10px; */
  padding: 15px;
  width: 100%;
  /* border:1px solid #e5e5e5;
    border-radius:2px; */
  display: table;
  content: " ";

  height: 270px;
  position: relative;
  margin-bottom: 15px;
}

.Slide {
  text-align: center;
  position: relative;
  height: 100%;
}

.aw-btn-slide {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 10;
  border: none;
  background: linear-gradient(to right, white 15%, rgba(255, 255, 255, 0) 100%);
}

.aw-btn-slide {
  display: -webkit-flex;
  display: flex;
  place-items: center;
  height: 100%;
  cursor: pointer;
}

.aw-btn-slide>div {
  padding: 10px 15px;
  background-color: white;
  border-radius: 5px;
}

.aw-btn-slide.aw-btn-slide-previous {
  left: 0px;
}

.aw-btn-slide.aw-btn-slide-next {
  right: 0px;
  background: linear-gradient(to left, white 15%, rgba(255, 255, 255, 0) 100%);
}

.App-logo {
  height: 80px;
}

/* DEMO STYLES */
.nav {
  padding: 30px 0;
  background-color: #222;
}

.nav a {
  display: inline-block;
  width: 100px;
  text-align: center;
  margin: 0 15px;
  padding: 10px;
  border: 1px rgba(255, 255, 255, 0.5) solid;
  transition: all 0.3s linear;
  text-decoration: none;
  color: white;
}

.nav a.active {
  background-color: #61DAFB;
  color: #222;
}

section {
  padding: 20px 0;
  max-width: 600px;
  margin: 0 auto;
}

/* #endregion */
/* #region Card */
/* Card */
.card {
  /* border: 3px #f3f3f3 solid; */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s linear;
  cursor: pointer;
  background-color: #f3f3f3;
  padding: 10px;
  max-width: 220px;
  text-align: left;
  margin: 0 auto;
}

.card:hover {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}

.card img {
  max-width: calc(100%);
  margin: 0 auto;
  display: block;
}

.card .index {
  /* background-color: rgba(0, 0, 0, 0.2);
    color: #fff; */
  /* background-color: green;
    color: yellow; */

  background-color: #e2e2e2;
  border-radius: 0 0 5px 5px;
  font-weight: 600;

  line-height: 20px;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 0 10px;
}

.card .price {
  margin: 0;
  padding: 10px 0;
  font-weight: bold;
}

.card .details {
  position: relative;
}

/* .card .features {
    list-style: none;
    padding: 0;
    margin: 0; }
    .card .features li {
      padding-left: 24px;
      margin-right: 10px;
      display: inline-block; }
      .card .features li span {
        display: none; }
      .card .features li.icon-bed {
        background: url(./assets/bed-left-side.svg) left center;
        background-size: auto 100%;
        background-repeat: no-repeat; }
      .card .features li.icon-bath {
        background: url(./assets/bathtub.svg);
        background-size: auto 100%;
        background-repeat: no-repeat; }
      .card .features li.icon-car {
        background: url(./assets/car-compact.svg);
        background-size: auto 100%;
        background-repeat: no-repeat; } */

/* #endregion */
/* #region cards slider */
.cards-slider-wrapper {
  height: 100%;
  /*added */
  display: -webkit-flex;
  display: flex;
  position: absolute;
  transition: -webkit-transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955), -webkit-transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.cards-slider-wrapper .card {
  -webkit-flex: 1 1;
          flex: 1 1;
  /* width:260px; */
  min-width: 200px;
  opacity: 0.7;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  box-shadow: none;
  background-color: white;
  border-color: white;
  transition: opacity 300ms linear, border-color 300ms linear, background-color 300ms linear, -webkit-transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: opacity 300ms linear, border-color 300ms linear, background-color 300ms linear, transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: opacity 300ms linear, border-color 300ms linear, background-color 300ms linear, transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955), -webkit-transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.cards-slider-wrapper .card .details {
  Xopacity: 0;
  transition: opacity 150ms linear;
}

.col {
  height: 100%;
  position: relative;
}

.cards-slider,
.cards-slider.event-slider {
  position: relative;
  max-width: 226px;
  max-width: 226px;
  height: 100%;
  /* USED TO control layout properties*/
  margin: 0 auto;
}

.cards-slider.event-slider {
  position: relative;
  /* max-width: 226px; */
  /* height:100%; */
  max-width: calc(100vw - 100px);
  height: calc(100% - 70px);
  /* USED TO control layout properties*/
  margin: 0 auto;
}

/* .cards-slider:after {
    content: '';
    display: block;
    width: 100%;
    height: 264px;
    outline: 5px solid #61DAFB;
    position: absolute;
    top: 0;
    left: 0; } */

/* #endregion */

.card {
  width: 900px;
}

.cards-slider .active-slide-card {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  /* background-color: #f3f3f3; */
  /* border-color: #f3f3f3;  */
}

.cards-slider .active-slide-card .details {
  opacity: 1;
}

.cardSlideView,
.cardW {
  width: 300px;
  max-width: calc(100vw - 50px);
}

.alignFor2 {
  width: 100%;
  /* max-width: calc(100vw - 100px); */
}

@media(min-width:768px) {
  .aw-slide-wrapper {
    /* border:1px solid #e5e5e5; */
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
  }
}
/* *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    background: #000;
  } */
  
  .slider {
    overflow: hidden;
    position: relative;
    /* height: 100vh; */
    /* height: calc(100% - 70px); */
    height:100%;
    color: #fff;
    height:calc(100vh - 200px);
    min-height:400px; 
    xpadding-bottom:15px;


  }
  /* .slider__top-heading,.XXaw-slider__slide-date {
    z-index: 12;
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    transition: all 0.5s 1s;
    transform: translateY(-30px);
    opacity: 0;
  }
.XXaw-slider__slide-date {
    text-transform: initial;
    /* transition: all 0.5s; */
    /* transition-delay: 0.65s;
    transition: all 0.3s 0.6s;
  }  
  .slider.s--ready .slider__top-heading {
    transform: translateY(0);
    opacity: 1;
  } */

  .slider__slides {
    position: relative;
    height: 100%;
  }
  .slider__slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    padding: 15px 15px 70px 15px;
    /* padding: 15px; */
  }
  .slider__slide.s--active {
    pointer-events: auto;
  }
  .slider__slide-content {
    z-index: 6;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    text-transform: uppercase;
    line-height: 1;

    max-width:1000px;
    text-align:center;
    margin:auto;
  }

  /* .slider__slide.s--active .XXaw-slider__slide-date {
    transform: translateY(0);
    opacity: 1;
  } */
  .the-slider__slide-date{
    font-size:14px;
    padding:10px;
  }
  /* .aw-slider__slide-date {
    z-index: 12;
    position: absolute;
    left: 0;
    top: 100px; 
    top: 25px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    

    margin-bottom: 20px;
    font-size: 24px;
    letter-spacing: 2px;
    transform: translateX(20px);
    opacity: 0;
    transition: 0.5s;
  } */
  /* .slider__slide.s--active .aw-slider__slide-date {
    transition-delay: 0.65s;
    opacity: 1;
    transform: translateX(0);
  } */

  .slider__slide-subheading {
    text-transform: capitalize;
    margin-bottom: 20px;
    font-size: 24px;
    letter-spacing: 2px;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
    transition: 0.5s;
    position: absolute;
    /* top: 90px; 
    height: 100px; */
    /* overflow: hidden;    */
  }
  .slider__slide.s--active .slider__slide-subheading {
    transition-delay: 0.65s;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .slider__slide-heading {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    margin-bottom: 20px;
    font-size: 60px;
    letter-spacing: 12px;
    position: absolute;
    bottom: 50px;
  }
  .slider__slide-heading span {
    display: block;
    opacity: 0;
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
    transition: all 0.3333333333s;
  }
  .slider__slide.s--prev .slider__slide-heading span {
    -webkit-transform: translateY(60px);
            transform: translateY(60px);
  }
  .slider__slide.s--active .slider__slide-heading span {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .slider__slide-heading span:nth-child(1) {
    transition-delay: 0s;
  }
  .slider__slide.s--active .slider__slide-heading span:nth-child(1) {
    transition-delay: 0.3333333333s;
  }
  .slider__slide-heading span:nth-child(2) {
    transition-delay: 0.1s;
  }
  .slider__slide.s--active .slider__slide-heading span:nth-child(2) {
    transition-delay: 0.4333333333s;
  }
  .slider__slide-heading span:nth-child(3) {
    transition-delay: 0.2s;
  }
  .slider__slide.s--active .slider__slide-heading span:nth-child(3) {
    transition-delay: 0.5333333333s;
  }
  .slider__slide-heading span:nth-child(4) {
    transition-delay: 0.3s;
  }
  .slider__slide.s--active .slider__slide-heading span:nth-child(4) {
    transition-delay: 0.6333333333s;
  }
  .slider__slide-heading span:nth-child(5) {
    transition-delay: 0.4s;
  }
  .slider__slide.s--active .slider__slide-heading span:nth-child(5) {
    transition-delay: 0.7333333333s;
  }
  .slider__slide-heading span:nth-child(6) {
    transition-delay: 0.5s;
  }
  .slider__slide.s--active .slider__slide-heading span:nth-child(6) {
    transition-delay: 0.8333333333s;
  }
  .slider__slide-heading span:nth-child(n+7) {
    transition-delay: 0.6s;
  }
  .slider__slide.s--active .slider__slide-heading span:nth-child(n+7) {
    transition-delay: 0.9333333333s;
  }
.slider__slide-title{
  color:#fff;
}
  .slider__slide-readmore,.slider__slide-location {
    /* position: absolute; */
    font-size: 14px;
    bottom: 5px;
    right:0px;

    /* position: relative;
    font-size: 14px; */
    /* text-transform: lowercase; */
    text-transform: capitalize;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    cursor: pointer;
    opacity: 0;
    transition: 0.5s;

    color: white;
    background-color: #ffffff40;
    padding: 5px;
    border-radius: 100px;
  }
  .slider__slide-location {
    right:auto;
    left:0px;
  }  
  .slider__slide.s--active .slider__slide-readmore,.slider__slide.s--active .slider__slide-location {
    transition-delay: 0.65s;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  /* .slider__slide-readmore:before {
    content: "";
    position: absolute;
    left: -2px;
    top: -3px;
    width: calc(100% + 4px);
    height: calc(100% + 6px);
    background: rgba(255, 255, 255, 0.4);
    transform: scaleX(0.3);
    transform-origin: 0 50%;
    transition: transform 0.3s;
  }
  .slider__slide-readmore:hover:before {
    transform: scaleX(1);
  } */
  .slider__slide-parts {
    position: absolute;
    left: 0;
    top: 0;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
  }
  .slider__slide-parts:after {
    content: "";
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }
  .slider__slide-part {
    position: relative;
    width: 25%;
    height: 100%;
  }
  .slider__slide-part-inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background-size: 0 0;
    background-repeat: no-repeat;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  }
  .slider__slide-part-inner:before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100%;
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: opacity 0.25s;
    opacity: 0;
  }
  .slider__slide-part:nth-child(1) .slider__slide-part-inner {
    z-index: 3;
    transition-delay: 0.24s;
    -webkit-transform: translateX(-32.5%);
            transform: translateX(-32.5%);
  }
  .slider__slide.s--active .slider__slide-part:nth-child(1) .slider__slide-part-inner {
    transition-delay: 0.28s;
  }
  .slider__slide-part:nth-child(1) .slider__slide-part-inner:before {
    left: 0vw;
    transition-delay: 0.365s;
  }
  .slider__slide.s--active .slider__slide-part:nth-child(1) .slider__slide-part-inner:before {
    transition-delay: 0.28s;
  }
  .slider__slide-part:nth-child(2) .slider__slide-part-inner {
    z-index: 2;
    transition-delay: 0.16s;
    -webkit-transform: translateX(-65%);
            transform: translateX(-65%);
  }
  .slider__slide.s--active .slider__slide-part:nth-child(2) .slider__slide-part-inner {
    transition-delay: 0.36s;
  }
  .slider__slide-part:nth-child(2) .slider__slide-part-inner:before {
    left: -25vw;
    transition-delay: 0.285s;
  }
  .slider__slide.s--active .slider__slide-part:nth-child(2) .slider__slide-part-inner:before {
    transition-delay: 0.36s;
  }
  .slider__slide-part:nth-child(3) .slider__slide-part-inner {
    z-index: 1;
    transition-delay: 0.08s;
    -webkit-transform: translateX(-97.5%);
            transform: translateX(-97.5%);
  }
  .slider__slide.s--active .slider__slide-part:nth-child(3) .slider__slide-part-inner {
    transition-delay: 0.44s;
  }
  .slider__slide-part:nth-child(3) .slider__slide-part-inner:before {
    left: -50vw;
    transition-delay: 0.205s;
  }
  .slider__slide.s--active .slider__slide-part:nth-child(3) .slider__slide-part-inner:before {
    transition-delay: 0.44s;
  }
  .slider__slide-part:nth-child(4) .slider__slide-part-inner {
    z-index: 0;
    transition-delay: 0s;
    -webkit-transform: translateX(-130%);
            transform: translateX(-130%);
  }
  .slider__slide.s--active .slider__slide-part:nth-child(4) .slider__slide-part-inner {
    transition-delay: 0.52s;
  }
  .slider__slide-part:nth-child(4) .slider__slide-part-inner:before {
    left: -75vw;
    transition-delay: 0.125s;
  }
  .slider__slide.s--active .slider__slide-part:nth-child(4) .slider__slide-part-inner:before {
    transition-delay: 0.52s;
  }
  .slider__slide.s--active .slider__slide-part-inner {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-timing-function: ease;
  }
  .slider__slide.s--active .slider__slide-part-inner:before {
    opacity: 1;
  }
  .slider__control {
    z-index: 100;
    position: absolute;
    /* left: 50px; */
    left: 0px;
    /* top: 50%; */
    top: 20px;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    /* transform: translateX(-50px); */
    opacity: 0;
    transition: all 0.5s 1s;
    cursor: pointer;
  }
  .slider__control:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
    border: 2px solid #000;
    border-bottom: none;
    border-right: none;
    -webkit-transform: translateX(5px) rotate(-45deg);
            transform: translateX(5px) rotate(-45deg);
  }
  .slider__control--right {
    left: auto;
    right: 50px;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  .slider__control--right:before {
    -webkit-transform: translateX(-5px) rotate(135deg);
            transform: translateX(-5px) rotate(135deg);
  }
  /* .slider.s--ready .slider__control {
    transform: translateX(0);
    opacity: 1;
  } */
  .s--active .slider__control {
    /* transform: translateX(0); */
    opacity: 1;
  }
  .icon-link {
    z-index: 100;
    position: absolute;
    left: 5px;
    bottom: 5px;
    width: 32px;
  }
  .icon-link img {
    width: 100%;
    vertical-align: top;
  }
  .icon-link--twitter {
    left: auto;
    right: 5px;
  }


  @media (max-width: 767px){
    .slider__control {
        /* left: 10px; */
        /* top: 50px; */
        width: 30px;
        height: 30px;
      }    
      .slider__slide {
        padding: 15px;
      }        
      .slider__control:before {
        width: 16px;
        height: 16px;
        margin-left: -8px;
        margin-top: -8px;
      }
/* 
      .slider__control--right {
        left: auto;
        right: 10px;
      }    */
    .aw-slider__slide-date {
        top: 15px;
      }
      .slider__slide-heading {
        /* margin-bottom: 20px; */
        font-size: 20px;
        letter-spacing: 4px;
      }      
  }
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .app__wrap>*{
  z-index:1000 !important;
} */
.btn-success{
  color:yellow;
}
body,.bgColorBody,.aw-main-content-wrap,.toolbar{
  background-color: #f7f7f7;
}
.aw-mediaWrap,.cards-slider .active-slide-card,.aw-sidebarItemWrap .btn,.loadStatusMsg{
  background-color: #f2f2f2 !important;
}
.loadStatusMsg{
  padding:15px;
  text-align:center;
  font-size:20px;
  margin-bottom:15px;
}
.aw-sidebarItemWrap .btn{
  border-color:#f2f2f2 !important;
}
.card-details{
  height: 90px;
    overflow: hidden;
}
@media (min-width: 768px){
  .profileCard__Wrapper,.aw-eachPost,.aw-main-content-wrap,.aw-hasMediaWrap,.aw-slide-wrapper,.cards-slider .active-slide-card{
    border-color:#f2f2f2;
    /* border-color:#f2f2f2 !important; */
  }
  .aw-sidebarItemWrap{
    border-width:1px;
  }
  .jform-control{
    border-width:1px 0 0 0;
  }
  .aw-sidebarItemWrap,.jform-control{
    border-color:#f2f2f2;
  }
}
@media (max-width:767px){
  .hideInMobile{
    display:none;
  }
  .toolbar{
    background-color: #fff;
  }
}



/* Transfered from SideDrawer.css */

.aw-main-content-wrap {
  width: 100%;
  max-width: 1000px;
  display: block;
  margin:auto;
  /* z-index: 10 */
}
.aw-content-mid-right {
  padding-right: 0;
}

.aw-content-mid-center,
.aw-content-top-center {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 767px) {
  .aw-body-img-wrap.hasBg {
    min-height: 100px;
  }  
  .aw-content-mid-left,
  .aw-content-mid-center,
  .aw-content-mid-right {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .aw-body-img-wrap {
    /* min-height: 85px;
    min-height: 75px; */

    /* min-height: 45px; */
    /* margin-bottom:-50px; */
    margin-bottom: 5px;
    z-index:0;
    /* padding-top: 85px; */
  }  
  .aw-body-img-wrap.hasBg {
    min-height:200px;
  }
  .aw-main-content-wrap {
    /* margin: -70px auto auto auto; */
    border-radius: 3px;
    /* z-index: 10; */
  }
/* .aw-body-content-wrap{
  z-index: 10;
}   */

}


/* SIDEBAR STYLES */
.aw-sidebarItemWrap {
    margin-bottom: 15px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 2px;
  }
  .aw-sidebarItemWrap * {
    max-width: 100%;
    text-align: center;
  }
  
  .aw-sidebarItemTitle {
    font-size: 18px;
    xbackground-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
  }
  
  .aw-sidebarItemWrap img {
    margin: auto;
    display: block;
    width: 100%;
  }
  
  .aw-sidebarItemWrap p {
    padding: 10px;
    margin: 0;
  }
  
  .aw-sidebarItemBtn {
    width: 100%;
    border-radius: 0;
  }
  
  .aw-sidebarItemWrap .btn {
    width: 100%;
    border-radius: 0;
    padding: 10px;
    background-color: #e5e5e5;
    border-color: #e2e2e2;
    color: green;
  }
  
  .aw-subscribe * {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  
  .jform-control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #fff;
    /* border: 1px 0 solid transparent; */
    border-color: transparent;
    /* border-top: 1px solid #ccc; */
    border-top: 1px solid #f2f2f2;
  }
