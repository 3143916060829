.aw-MainNav{
  display:flex;
  align-items: center;
}
.aw-MainNav .aw-MainNav-item{
  margin:auto 15px;
  text-align: center;
  font-size: 12px;
}
.aw-MainNav-login{
  font-weight: 800;
  letter-spacing: 1px;
  color: green;
  cursor: pointer;
}
.aw-MainNav a{
  color:#000;
}
@media (max-width: 767px) {
  .aw-MainNav{
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
    display:flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    position:fixed;
    bottom:0;
    left:0;
    right:0; 
    height:50px;
    transition: all 0.2s ease-in-out;
    transition-delay: 1s;
  }
  .aw-MainNav-switch{
    width:40%;
  }
  .aw-MainNav  .switchDesigner {
    height: 40px;
    border-radius: 5px;
  }

  .aw-MainNav .aw-MainNav-item{
    flex:1;
    margin:auto 5px;
  }
  .hideHeader .aw-MainNav{
    opacity:0;  
    transition: all 0s;
  }  
}
@media (min-width: 768px) {
  .call__createPost{
    width:200px;
  }
  .aw-MainNav i{
    display:none;
  }  
  .aw-MainNav .aw-MainNav-item{
    font-size: 14px;
    letter-spacing: 1px;
  }    
}
  