/* SIDEBAR STYLES */
.aw-sidebarItemWrap {
    margin-bottom: 15px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 2px;
  }
  .aw-sidebarItemWrap * {
    max-width: 100%;
    text-align: center;
  }
  
  .aw-sidebarItemTitle {
    font-size: 18px;
    xbackground-color: #f7f7f7;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
  }
  
  .aw-sidebarItemWrap img {
    margin: auto;
    display: block;
    width: 100%;
  }
  
  .aw-sidebarItemWrap p {
    padding: 10px;
    margin: 0;
  }
  
  .aw-sidebarItemBtn {
    width: 100%;
    border-radius: 0;
  }
  
  .aw-sidebarItemWrap .btn {
    width: 100%;
    border-radius: 0;
    padding: 10px;
    background-color: #e5e5e5;
    border-color: #e2e2e2;
    color: green;
  }
  
  .aw-subscribe * {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  
  .jform-control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #fff;
    /* border: 1px 0 solid transparent; */
    border-color: transparent;
    /* border-top: 1px solid #ccc; */
    border-top: 1px solid #f2f2f2;
  }