h2 {
    color: white;
  }
  .strip__scroller{
    margin: auto;
    /* max-width: 1000px; */
    max-width: 100%;
  }
  .scrollControls{
      display:none;
  }
  .scrolling-wrap{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-snap-align: center;
    /* max-width: 1000px; */
  }

  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .scrolling-wrapper .scrolling-card {
    display: inline-block;
  }
  
  .scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .scrolling-wrapper-flexbox .scrolling-card {
    flex: 0 0 auto;
    /* margin-bottom: 10px;     */
  }
  
  .scrolling-card {
    /* border: 2px solid red; */
    /* width: 90px; */
    max-width:100px;

    color:#333;
    padding:10px;
    /* height:150px; */
    /* height: 75px; */
    /* height: 100%; */
    margin:auto;
    /* background: black; */
    display: flex;
    flex-direction: column;
    /* justify-items: center; */
    align-items: center;
  }
  .scrolling-img-wrap{
      width:50px;
      height:50px;
      overflow:hidden;
      border-radius:50px;
      background-color: #e2e2e2;
      display: flex;
    place-content: center;
  }
  .scrolling-img-wrap>.fa{
    font-size: 45px;
    font-size: 30px;
    padding: 10px;
    color: #f7f7f7;
  }
  .scrolling-img-wrap > img{
    min-width: 100%;
    max-height:100%
  }
  .scrolling-name{
      width:100%;
      text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
  
  .scrolling-wrapper, .scrolling-wrapper-flexbox {
    max-width:100%;
    -webkit-overflow-scrolling: touch;
  }
  @media(max-width:767px){
      .scrolling-wrapper::-webkit-scrollbar, .scrolling-wrapper-flexbox::-webkit-scrollbar {
        display: none;
      }
  }

  .prev, .next {
    /* padding-top: 10px;
    padding-bottom: 10px; */
    padding:10px;
    height: 100%;
    cursor: pointer;
    color: black;
    transition: 0.6s ease;
  }
  
  .next {
    margin-left: 5px;
  }
  
  .prev {
    margin-right: 5px;
  }
  
  .prev:hover, .next:hover {
    color: white;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
  }
  .loadMore {
    background-color: yellow;
    border:10px solid green;
  }
  .loadMore.Cls0{
    background-color:green;
    border:10px solid green;
  }