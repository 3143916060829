body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .app__wrap>*{
  z-index:1000 !important;
} */
.btn-success{
  color:yellow;
}
body,.bgColorBody,.aw-main-content-wrap,.toolbar{
  background-color: #f7f7f7;
}
.aw-mediaWrap,.cards-slider .active-slide-card,.aw-sidebarItemWrap .btn,.loadStatusMsg{
  background-color: #f2f2f2 !important;
}
.loadStatusMsg{
  padding:15px;
  text-align:center;
  font-size:20px;
  margin-bottom:15px;
}
.aw-sidebarItemWrap .btn{
  border-color:#f2f2f2 !important;
}
.card-details{
  height: 90px;
    overflow: hidden;
}
@media (min-width: 768px){
  .profileCard__Wrapper,.aw-eachPost,.aw-main-content-wrap,.aw-hasMediaWrap,.aw-slide-wrapper,.cards-slider .active-slide-card{
    border-color:#f2f2f2;
    /* border-color:#f2f2f2 !important; */
  }
  .aw-sidebarItemWrap{
    border-width:1px;
  }
  .jform-control{
    border-width:1px 0 0 0;
  }
  .aw-sidebarItemWrap,.jform-control{
    border-color:#f2f2f2;
  }
}
@media (max-width:767px){
  .hideInMobile{
    display:none;
  }
  .toolbar{
    background-color: #fff;
  }
}



/* Transfered from SideDrawer.css */

.aw-main-content-wrap {
  width: 100%;
  max-width: 1000px;
  display: block;
  margin:auto;
  /* z-index: 10 */
}
.aw-content-mid-right {
  padding-right: 0;
}

.aw-content-mid-center,
.aw-content-top-center {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 767px) {
  .aw-body-img-wrap.hasBg {
    min-height: 100px;
  }  
  .aw-content-mid-left,
  .aw-content-mid-center,
  .aw-content-mid-right {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .aw-body-img-wrap {
    /* min-height: 85px;
    min-height: 75px; */

    /* min-height: 45px; */
    /* margin-bottom:-50px; */
    margin-bottom: 5px;
    z-index:0;
    /* padding-top: 85px; */
  }  
  .aw-body-img-wrap.hasBg {
    min-height:200px;
  }
  .aw-main-content-wrap {
    /* margin: -70px auto auto auto; */
    border-radius: 3px;
    /* z-index: 10; */
  }
/* .aw-body-content-wrap{
  z-index: 10;
}   */

}

