.overlay__bg {
    background: #000;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: .7;
    z-index: 300;
}

.overlay__container {
    position: fixed;
    /* max-width:100%;
    max-height:100%; */
    max-width:100vw;
    max-height:100vh;

    overflow:hidden;
    z-index: 301;

    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;  
    background-color: #fff; 
    padding:10px; 
}
.overlay__container>*{
    position:relative;
    min-width:250px;
    max-width:100vw;
    max-height:100vh;
}

.overlay__container-login input,.overlay__container-login  a{
    flex: 1 1 auto;
    border-color: transparent;
    width: 50%;
    text-align:center;
}

.dialog__list-items{
    padding: 5px 0;
}