.switchWrapper{
  display:flex;
  position:relative;
}
.switchWrapper label{
  margin-bottom:0;
  width:100%;
}
.switchDesigner{
  background-color: #e2e2e2;

  /*switch size*/
  /* width: 120px; */
  width: 100%;
  height: 25px; 
  min-width: 120px;

  /*switch labels*/
  font-weight: 300; font-size:11px; color: #000; 
  
  /* remove for square edges */
  border-radius: 999px; 
}
/* input[type="checkbox"].switch:checked + .switchDesigner {
  background-color: #008000;
} */

  input[type="checkbox"].switch {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin:auto;
  }
  input[type="checkbox"].switch + div {
    position: relative;
    vertical-align: middle;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: background-color, box-shadow;
    transition-property: background-color, box-shadow;
    cursor: pointer;
  }
  input[type="checkbox"].switch + div > div {
    position: absolute;
    width: calc(50% - 1px);
    height: calc(100% - 2px);
    border-radius: inherit;
    background: #fff;
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: transform, background-color;
    transition-property: transform, background-color;
    pointer-events: none;
    margin-top: 1px;
    margin-left: 1px;
  }
  input[type="checkbox"].switch + div span {
    position: absolute;
    width:50%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding:2px;
    text-align: center;
    font-size: 1.0em;
    top: 50%;
    transform: translateY(-50%);
  }

  input[type="checkbox"].switch + div span.switchLabel2 {
    right:0;
  }

  input[type="checkbox"].switch:checked + div > div {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }