.main {
  transition: -webkit-margin-left 0.3s ease-out;
  transition: margin-left 0.3s ease-out;
  transition: margin-left 0.3s ease-out, -webkit-transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
}

.show__in__mobile {
  float: right;
}

.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  max-width: calc(100vw - 50px);
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

/* .side-drawer.right {
  transform: translateX(100%);
  right:0;
  left:auto;
} */
.side-drawer.open {
  transform: translateX(0);
}

.side-drawer.right.open {
  /* transform: translateX(100%); */
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-top:60px;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a {
  color: #521751;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #fa923f;
}

.KEEPmainContentWrap {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {
  .show__in__mobile {
    display: none;
    margin-left: calc(((100vw - 1000px)/2) - 300px);
  }

  .side-drawer {
    padding-top: 56px;
  }

  .side-drawer.right {
    transform: translateX(100%);
    right: 0;
    left: auto;
  }

  .side-drawer.open {
    transform: translateX(0);
  }

}

@media (max-width: 767px) {
  .main {
    margin-top: 0;
  }

  .toolbar__logo {
    margin-left: 0;
  }

  .side-drawer {
    z-index: 202;
  }

  /* .main_header_strip{
    padding-top:76px;
  }
  .main_header_strip a{
    color:#000;
  } */
}